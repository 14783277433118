#pp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: 0 auto;
}

#pp-title {
    font-size: 45px;
    text-align: center;
    padding: 25px;
}

#pp-text {
    padding: 25px;
    font-size: 16px;
    line-height: 25px;
}

#cookie-toggle {
    display: inline-block;
    background-color: #212121;
    border: none;
    transform: none;
}

@media (max-width: 1200px) {
    #pp-container {
        width: 96vw;
    }
}