@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

pre {
  white-space: pre-line;
}

.link {
  color: white;
  text-decoration: none;
  cursor: default;
}

.link:hover {
  color: gold !important;
  cursor: pointer;
  text-decoration: underline;
}

.light-bg-link {
  color: #bdc8c8;
  text-decoration: none;
}

.light-bg-link:hover {
  color: gold;
  cursor: pointer;
  text-decoration: underline;
}

.non-obvious-link {
  color: white;
  cursor: default;
}

.non-obvious-link:hover {
  color: #bdc8c8;
  cursor: pointer;
  text-decoration: underline;
}

a {
  color: #bdc8c8;
}

a:hover {
  color: gold;
}

html {
  background-color: #212121;
  color: white;
}
.searchlist {
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.searchlist::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

#search-sub-wrapper {
    position: relative;
}

#search-icon {
    position: absolute;
    top: 33px;
    right: 10px;
}

.hidden {
    visibility: hidden;
}

.MuiAutocomplete-input {
    color: white !important;
}

.MuiFormLabel-root {
    color: white !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: white !important;
    color: white;
}

.MuiAutocomplete-endAdornment {
    color: white !important;
}

.MuiSvgIcon-root {
    color: white;
}
.header-wrapper {
    width: 100%;
    height: 80px;
    display: flex;
    background-color: #212121;
    align-items: center;
    border-bottom: solid .5px white;
    position: fixed;
    z-index: 1001;
}

.sticky {
    position: fixed;
    margin: unset;
    border-bottom: solid .5px white;
}

#shop-by-sub-text {
    padding-left: 15px;
    font-size: 25px;
    letter-spacing: 1.5px;
    font-family: 'Raleway', sans-serif;
    /* Desktop only*/
    border-right: 1px solid #bdc8c8;
    padding-right: 10px;
    color: white;
}

#discover-text {
    font-family: 'Raleway', sans-serif;
    font-weight: 900;
    padding-left: 15px;
    margin-left: 15px;
}

#search {
    width: 300px;
    margin-left: 15px;
}

.extra-padding-top {
    padding-top: 80px;
}

#right-nav-wrapper {
    flex-grow: 1;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
}

#pp-link {
    text-align: right;
    font-size: 12px;
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .header-wrapper {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        position: unset;
    }
    #shop-by-sub-text {
        padding-top: 15px;
        padding-bottom: 10px;
        border-right: none;
        padding-right: 0;
        order: -1;
        text-decoration: underline;
    }
    #discover-text {
        padding-left: unset;
        margin-left: unset;
        padding: 10px 15px 10px 15px;
        order: -1;
        text-justify: distribute;
        text-align: justify;
    }
    #pp-link {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
    }
    #right-nav-wrapper {
        padding-right: unset;
    }
    #search {
        margin: 0 auto;
    }
    #search-wrapper-wrapper {
        width: 100%;
        background-color: #212121;
        z-index: 1001;
        margin: 0 auto;
    }
}

@media (max-width: 1313px) and (min-width: 1201px) {
    #discover-text {
        display: none;
    }
}
.comment-wrapper {
    min-width: 350px;
    max-width: 350px;
    background-color: #272c34;
    border-radius: 5px;
    overflow-wrap: break-word;
    margin: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.subreddit-comment {
    margin-left: 16px;
}

.comment-img-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}

.comment-img-wrapper-box {
    background-color: white;
    margin: 15px;
    border: 2px solid #272c34;
}

.comment-img-wrapper-box:hover {
    border: 2px solid gold;
}

#comment-text-container {
    margin-left: 16px;
    margin-bottom: 3px;
}

.comment-text-sub-container {
    border-left: 1px solid #bdc8c8;
    padding: 14px 14px 14px 20px;
}

.comment-text {
    font-size: 12px;
    line-height: 1.2;
    overflow-y: hidden;
}

.collapsed {
    max-height: 74px;
}

.expand-button {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    align-items: center;
    color: #bdc8c8;
    font-size: 12px;
    width: 75px;
    margin: -15px 10px 20px 15px;
    float: right;
}

.expand-button:hover {
    color: gold;
    cursor: pointer;
}

.username {
    font-size: 14px;
    padding-right: 15px;
    float: right;
    align-self: flex-end;
}

.permalink {
    padding-left: 15px;
    font-size: 12px;
}

.score {
    align-self: flex-end;
    padding-right: 15px;
}

.score-positive {
    color: gold;
}

.score-negative {
    color: #9494FF;
}

.product-anchor {
    margin: 0 auto;
    padding: 15px;
    text-decoration: underline;
}

#external-link-icon {
    position: absolute;
    color: gold;
    right: 10px;
    top: 10px;
}

#amazon-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 10px;
    top: 10px;
    background-color: white;
}

.actual-ad {
    background-color: white;
}

@media (max-width: 600px) {
    .comment-wrapper {
        min-width: 280px;
        max-width: 280px;
        padding: 10px;
    }
    .comment-img-wrapper-box {
        margin: 10px;
    }
    #comment-text-container {
        margin-left: 12px;
    }
    .subreddit-comment {
        margin-left: 11px;
    }
    .product-anchor {
        padding: 5px 20px 5px 20px;
    }
    #amazon-icon {
        left: 5px;
        top: 5px;
        background-color: white;
    }
    #external-link-icon {
        right: 5px;
        top: 5px;
    }
}

.product-img {
    width: 100%;
    height: 100%;
}
.spinner {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #bdc8c8;
    background: linear-gradient(to right, #bdc8c8 10%, rgba(172, 209, 205, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
}

.spinner:before {
    width: 50%;
    height: 50%;
    background: #bdc8c8;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.spinner:after {
    background: #212121;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.MuiInputBase-input {
    color: white !important;
}

#page-content {
    padding-top: 80px;
}

#controls-wrapper {
    display: flex;
    margin-left: 150px;
    margin-right: 150px;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.dropdown-item {
    width: 150px;
}

#title {
    padding-top: 15px;
    text-align: center;
    font-size: 40px;
}

#subtitle {
    font-size: 20px;
}

#disclaimer {
    position: absolute;
    right: 10px;
    font-size: 10px;
}

#comment-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.error {
    color: #bdc8c8;
    font-size: 50px;
    margin: 40px;
}

@media (max-width: 500px) {
    #title {
        font-size: 25px;
    }
}

@media (max-width: 1200px) {
    #page-content {
        padding-top: unset;
    }
    #title {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    #subtitle {
        font-size: 14px;
    }
    #disclaimer {
        width: 100%;
        right: unset;
        margin: 0 auto;
        text-align: center;
        font-size: 10px;
        color: #bdc8c8;
    }
    #dropdown-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: unset;
        margin-top: 25px;
    }
}

@media (max-width: 650px) {
    #controls-wrapper {
        flex-direction: column;
        margin-left: unset;
        margin-right: unset;
    }
}

@media (max-width: 800px) {
    .error {
        font-size: 30px;
    }
}
#pp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin: 0 auto;
}

#pp-title {
    font-size: 45px;
    text-align: center;
    padding: 25px;
}

#pp-text {
    padding: 25px;
    font-size: 16px;
    line-height: 25px;
}

#cookie-toggle {
    display: inline-block;
    background-color: #212121;
    border: none;
    transform: none;
}

@media (max-width: 1200px) {
    #pp-container {
        width: 96vw;
    }
}
